import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { useQueryClient } from "react-query";
import { Box, Button, Typography } from "@mui/material";

import { ADMIN_BID_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import ADMIN_COUPON_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_COUPON_QUERY";
import { FreightType } from "@sellernote/_shared/src/types/common/common";
import { UserCoupon } from "@sellernote/_shared/src/types/forwarding/coupon";
import { formatDate } from "@sellernote/_shared/src/utils/common/date";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

import Modal from "../../../../components/Modal";
import Table, {
  TableBodyRow,
  TableHeadCell,
} from "../../../../components/Table";

type CellId = keyof UserCoupon | "campaignName" | "registerCoupon";

function QuotationCouponModal({
  showsQuotationCouponModal,
  setShowsQuotationCouponModal,
  teamId,
  userId,
  freightType,
  shipmentId,
}: {
  showsQuotationCouponModal: boolean;
  setShowsQuotationCouponModal: Dispatch<SetStateAction<boolean>>;
  teamId: number;
  userId: number;
  freightType: FreightType;
  shipmentId: number;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const { data: couponList } = ADMIN_COUPON_QUERY.useGetAdminUserCouponList({
    teamId,
    userId,
    couponType: freightType,
  });

  const {
    mutate: registerCoupon,
    ResponseHandler: ResponseHandlerOfRegisterCoupon,
  } = ADMIN_COUPON_QUERY.useRegisterCoupon();

  const handleCouponRegister = useCallback(
    (couponCode: string) => {
      registerCoupon(
        {
          couponCode,
          pathParams: {
            shipmentId: shipmentId,
          },
        },
        {
          onSuccess: () => {
            handleSnackbarOpen("쿠폰을 등록했습니다.");

            queryClient.invalidateQueries(
              ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
                bidId: shipmentId,
              })
            );

            setShowsQuotationCouponModal(false);
          },

          onError: ({ response }) => {
            if (response?.data.errorCode === "E314") {
              handleSnackbarOpen("이미 발급된 쿠폰입니다.", "error");
              return;
            }
            handleSnackbarOpen("쿠폰등록에 실패했습니다.", "error");
          },
        }
      );
    },
    [
      registerCoupon,
      shipmentId,
      handleSnackbarOpen,
      queryClient,
      setShowsQuotationCouponModal,
    ]
  );

  const headCells: TableHeadCell<CellId>[] = [
    {
      id: "campaignName",
      disablePadding: false,
      label: "쿠폰명",
    },
    {
      id: "couponType",
      disablePadding: false,
      label: "쿠폰명",
    },

    {
      id: "code",
      disablePadding: false,
      label: "코드",
    },

    {
      id: "discountPrice",
      disablePadding: false,
      label: "할인 금액",
    },

    {
      id: "dueDate",
      disablePadding: false,
      label: "쿠폰 만료일",
    },
    {
      id: "registerCoupon",
      disablePadding: false,
      label: "쿠폰등록",
    },
  ];

  const rowList = useMemo(() => {
    if (!couponList?.list) return [];

    return couponList.list.map((coupon) => {
      const row: TableBodyRow<CellId> = {
        campaignName: coupon.campaign.campaignName,
        couponType: coupon.couponType,
        code: coupon.code,
        discountPrice: coupon.discountPrice,
        dueDate: formatDate({
          date: coupon.dueDate,
          type: "YY_MM_DD",
        }),
        registerCoupon: (
          <Button onClick={() => handleCouponRegister(coupon.code)}>
            등록하기
          </Button>
        ),
      };

      return row;
    });
  }, [couponList?.list, handleCouponRegister]);

  return (
    <>
      <Modal
        isOpened={showsQuotationCouponModal}
        modalBody={
          <Box>
            <Typography variant="h6">쿠폰리스트</Typography>

            <Table
              sx={{ maxHeight: 400 }}
              rows={rowList}
              headCells={headCells}
            />
          </Box>
        }
        handleClose={() => setShowsQuotationCouponModal(false)}
      />

      {ResponseHandlerOfRegisterCoupon}
    </>
  );
}

export default QuotationCouponModal;
