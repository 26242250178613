import { Box } from "@mui/material";

import { TrelloBidDetail } from "@sellernote/_shared/src/types/forwarding/trello";

import useShipmentCommentList from "../../../../../hooks/useShipmentCommentList";

import CommentList from "../../../../../components/CommentList";

function ShipmentCommentList({
  trelloDetailData,
}: {
  trelloDetailData: TrelloBidDetail;
}) {
  const {
    handleCommentInputChange,
    handleCommentUpdate,
    comment,
    shipmentCommentList,
    handleCommentEdit,
    handleCommentDelete,
    ResponseHandler: ResponseHandlerOfShipmentCommentList,
  } = useShipmentCommentList({ shipmentId: trelloDetailData.id });

  return (
    <Box sx={{ display: "flex", mt: 1 }}>
      <CommentList
        commentDataList={trelloDetailData.management.comments}
        commentPageType={"AutoShipment"}
      />

      <CommentList
        comment={comment}
        onCommentInputChange={handleCommentInputChange}
        onCommentUpdate={handleCommentUpdate}
        commentDataList={shipmentCommentList}
        commentPageType="ManualShipment"
        onCommentEdit={handleCommentEdit}
        onCommentDelete={handleCommentDelete}
      />

      {ResponseHandlerOfShipmentCommentList}
    </Box>
  );
}

export default ShipmentCommentList;
