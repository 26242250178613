import { useState } from "react";
import { useQueryClient } from "react-query";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import { ADMIN_BID_WAREHOUSE_OPTION_LIST } from "@sellernote/_shared/src/constants/forwarding/adminBid";
import ADMIN_BID_QUERY, {
  ADMIN_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";

import useSnackbar from "../../../../hooks/useSnackbar";

function WarehouseInfo({ bidDetail }: { bidDetail: AdminBidDetail }) {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const [warehouseId, setWarehouseId] = useState(bidDetail.wareHouse.id);
  const [canEdit, setCanEdit] = useState(false);

  const {
    mutate: changeConsolidationInfo,
    isLoading,
    ResponseHandler: ResponseHandlerOfChangeConsolidationInfo,
  } = ADMIN_BID_QUERY.useChangeAdminBidConsolidationInfo(bidDetail.id);

  const handleWarehouseChange = () => {
    changeConsolidationInfo(
      {
        wareHouseId: warehouseId,
        isProductInfoChanged: true,
      },
      {
        onSuccess: () => {
          setCanEdit(false);
          handleSnackbarOpen("창고 정보가 수정되었습니다.");
          queryClient.invalidateQueries(
            ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
              bidId: bidDetail.id,
            })
          );
        },

        onError: () => {
          handleSnackbarOpen("창고 정보 수정에 실패했습니다.", "error");
          setWarehouseId(bidDetail.wareHouse.id);
        },
      }
    );
  };

  const handleEditModeClose = () => {
    setCanEdit(false);
    setWarehouseId(bidDetail.wareHouse.id);
  };

  return (
    <Grid container sx={{ marginTop: "32px" }}>
      <Grid item container alignItems="center" xs={12}>
        <Grid item xs={10}>
          <Typography variant="h6" component="div">
            창고 정보
          </Typography>
        </Grid>

        {!canEdit && APP_NAME === "shipda-admin" && (
          <Grid item>
            <Button onClick={() => setCanEdit(true)}>수정</Button>
          </Grid>
        )}

        {canEdit && (
          <Grid item container xs={2}>
            <Grid item>
              <Button disabled={isLoading} onClick={handleWarehouseChange}>
                확인
              </Button>
            </Grid>

            <Grid item>
              <Button
                disabled={isLoading}
                color="error"
                onClick={handleEditModeClose}
              >
                취소
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid item container xs={12}>
        <Grid item container alignItems="center" xs={4} spacing={1}>
          <Grid item>
            <Typography variant="body2" component="div">
              창고명 :
            </Typography>
          </Grid>

          <Grid item>
            {canEdit ? (
              isLoading ? (
                <Skeleton variant="text" height={40} width={120} />
              ) : (
                <FormControl sx={{ width: 120 }} size="small">
                  <InputLabel>창고명</InputLabel>

                  <Select
                    label="창고명"
                    value={warehouseId}
                    onChange={(e) => {
                      setWarehouseId(e.target.value as number);
                    }}
                  >
                    {ADMIN_BID_WAREHOUSE_OPTION_LIST.map((v) => {
                      return (
                        <MenuItem key={v.value} value={v.value}>
                          {v.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )
            ) : (
              <Typography variant="body1" component="div">
                {bidDetail.wareHouse.name}
              </Typography>
            )}
          </Grid>
        </Grid>

        <Grid item container alignItems="center" xs={4} spacing={1}>
          <Grid item>
            <Typography variant="body2" component="div">
              계산 기준 :
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body1" component="div">
              1CBM/{bidDetail.wareHouse.cbm}KG
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container xs={12}>
        <Grid item container alignItems="center" xs={4} spacing={1}>
          <Grid item>
            <Typography variant="body2" component="div">
              창고 담당자명 :
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body1" component="div">
              {bidDetail.wareHouse.managerName}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container alignItems="center" xs={4} spacing={1}>
          <Grid item>
            <Typography variant="body2" component="div">
              연락처 :
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body1" component="div">
              {bidDetail.wareHouse.managerPhone}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container alignItems="center" xs={4} spacing={1}>
          <Grid item>
            <Typography variant="body2" component="div">
              이메일 :
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body1" component="div">
              {bidDetail.wareHouse.managerEmail}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {ResponseHandlerOfChangeConsolidationInfo}
    </Grid>
  );
}
export default WarehouseInfo;
