import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { Button, Grid, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";

import ADMIN_BID_QUERY, {
  ADMIN_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import { ExporterInfo } from "@sellernote/_shared/src/types/common/common";
import regEx from "@sellernote/_shared/src/utils/common/regEx";
import Modal from "@sellernote/_shared-for-forwarding-admin/src/components/Modal";
import TextFieldWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/TextFieldWithReactHookForm";

import useSnackbar from "../../../../hooks/useSnackbar";

//TODO: 콘솔 EXW 수출자 정보에 출발지가 추가되어야하고 이에 따라 수정 기능도 추가해야함
function ChangeExporterInfoModal({
  showsChangeExporterInfoModal,
  handleChangeExporterInfoModalClose,
  exporterInfo,
  bidId,
}: {
  showsChangeExporterInfoModal: boolean;
  handleChangeExporterInfoModalClose: () => void;
  exporterInfo: ExporterInfo;
  bidId: number;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ExporterInfo>({
    defaultValues: exporterInfo,
  });

  const {
    mutate: changeShipmentExporterInfo,
    isLoading,
    ResponseHandler: ResponseHandlerOfChangeShipmentExporterInfo,
  } = ADMIN_BID_QUERY.useChangeShipmentExporterInfo();

  const handleExporterInfoChange = () => {
    changeShipmentExporterInfo(
      {
        bidId,
        exporters: [watch()],
      },
      {
        onSuccess: () => {
          handleSnackbarOpen("수출자 정보가 수정되었습니다.");

          queryClient.invalidateQueries(
            ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
              bidId,
            })
          );
        },

        onError: ({ response }) => {
          if (response?.data?.code === 404) {
            // 404일 때 에러메세지로 'shipment not found'가 나와 번역해서 정의함
            handleSnackbarOpen("의뢰를 찾을 수 없습니다.", "error");
            return;
          }

          if (response?.data?.code === 400) {
            handleSnackbarOpen(
              "수출자 정보 수정이 불가능한 상태입니다.",
              "error"
            );
          }
        },
      }
    );
  };

  return (
    <>
      <Modal
        isOpened={showsChangeExporterInfoModal}
        handleClose={() => handleChangeExporterInfoModalClose()}
        modalBody={
          <Grid
            container
            component="form"
            spacing={1}
            onSubmit={handleSubmit(handleExporterInfoChange)}
          >
            <Grid item xs={12}>
              <Typography
                sx={{ color: `${blue[300]}` }}
                variant="body1"
                component="div"
              >
                {`수출자 정보 수정`}
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <TextFieldWithReactHookForm
                label="COMPANY NAME"
                name="companyName"
                control={control}
                required={true}
              />
            </Grid>

            <Grid item xs={3}>
              <TextFieldWithReactHookForm
                label="NAME"
                name="personName"
                control={control}
                required={true}
              />
            </Grid>

            <Grid item xs={3}>
              <TextFieldWithReactHookForm
                label="EMAIL"
                name="personEmail"
                control={control}
                // pattern을 직접 입력해야 해서 email-validator를 사용하지 않고 따로 email regEx를 추가함
                pattern={regEx.email}
                errorMessage="이메일 형식에 맞게 입력해주세요."
                required={true}
              />
            </Grid>

            <Grid item xs={3}>
              <TextFieldWithReactHookForm
                label="TEL"
                name="personPhone"
                control={control}
                required={true}
              />
            </Grid>

            <Grid item xs={12}>
              <Button disabled={isLoading} type="submit" variant="contained">
                수정
              </Button>
            </Grid>
          </Grid>
        }
      />

      {ResponseHandlerOfChangeShipmentExporterInfo}
    </>
  );
}

export default ChangeExporterInfoModal;
