import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import {
  AdminBidDetail,
  AdminShipmentItemGroupForm,
  AdminShipmentItemGroupFormForExisting,
  ShipmentItem,
} from "@sellernote/_shared/src/types/forwarding/adminBid";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";

import { QuotationChangeTypeAfterCargoUpdate } from "../../../../pageContainers/bid/detail/types";

import QuotationResetModal from "../../../../pageContainers/bid/detail/components/QuotationResetModal";

import FCLItemForm from "./FCLItemForm";
import LCLAndAIRItemForm from "./LCLAndAIRItemForm";
import useItemUpdateRequest from "./useItemUpdateRequest";

/**
 * 화물수정 Flow
 * - 1. 화물 수정 Submit
 * - 2-1. 확정견적이 있는 경우
 *   - 화물 수정시 견적 재계산/리셋이 필요한 건지 확인(API 호출)
 *     - 2-1-1 견적 수정 필요
 *       - 모달을 띄워 견적 수정 방법을 선택(모달 Open)
 *       - '화물 수정 API' 호출 후, 성공시 선택된 수정 방법(확정견적 리셋이냐 수정이냐)에 따라 redirection처리
 *       - end
 *     - 2-1-2 견적 수정 불필요
 *       - '화물 수정 API' 호출
 *       - end
 * - 2-2. 확정견적이 없는 경우
 *   - '화물 수정 API' 호출
 *   - end
 */
function ItemUpdateModal({
  bidDetail,
  onItemUpdateModalClose,
  consolidationExporterGroupId,
}: {
  bidDetail: AdminBidDetail;
  onItemUpdateModalClose: () => void;
  consolidationExporterGroupId?: number;
}) {
  const history = useHistory();

  const initialItemGroupList: AdminShipmentItemGroupFormForExisting[] =
    useMemo(() => {
      const target = (() => {
        if (bidDetail.serviceType === "consolidation") {
          /** 특송은 수출자로 필터링 */
          return bidDetail.itemGroupList.filter(({ item }) => {
            return item.exporterGroup === consolidationExporterGroupId;
          });
        }

        return bidDetail.itemGroupList;
      })();

      return target.map((itemGroup) => {
        return { ...itemGroup, type: "existing" };
      });
    }, [
      bidDetail.itemGroupList,
      bidDetail.serviceType,
      consolidationExporterGroupId,
    ]);

  /** form 입력에서는 값들이 옵셔널일 수 있음(수정 시에는 수정한 항목만 필요, 새로운 화물 추가시는 특정 항목만 사용해야함) */
  const methods = useForm<{ itemGroupList: AdminShipmentItemGroupForm[] }>({
    defaultValues: {
      itemGroupList: initialItemGroupList,
    },
  });

  const initialCargoInputMode: ShipmentItem["mode"] = useMemo(() => {
    const baseItemGroup = (() => {
      if (bidDetail.serviceType === "consolidation") {
        /** 특송은 수출자로 필터링 */
        return initialItemGroupList.find(({ item }) => {
          return item.exporterGroup === consolidationExporterGroupId;
        });
      }

      return initialItemGroupList[0];
    })();

    if (!baseItemGroup) {
      throw new Error("Not found baseItemGroup");
    }

    if (!baseItemGroup.item.mode) {
      throw Error("Not found mode");
    }

    return baseItemGroup.item.mode;
  }, [
    bidDetail.serviceType,
    consolidationExporterGroupId,
    initialItemGroupList,
  ]);

  const [cargoInputMode, setCargoInputMode] = useState(initialCargoInputMode);

  const {
    watch,
    formState: { dirtyFields },
    reset,
  } = methods;

  const {
    checkNeedToChangeQuotationAndUpdateCargo,
    handleShipmentItemCargoUpdate,
    showsQuotationResetModal,
    setShowsQuotationResetModal,
    ResponseHandler: ResponseHandlerOfItemUpdateRequest,
  } = useItemUpdateRequest({
    shipmentDetail: bidDetail,
    consolidationExporterGroupId,
    cargoInputMode,
    itemGroupList: watch("itemGroupList"),
    dirtyFields,
    resetFormState: reset,
  });

  const handleShipmentItemCargoUpdateOnReset = (
    quotationChangeType: QuotationChangeTypeAfterCargoUpdate
  ) => {
    const successCallback = () => {
      if (quotationChangeType === "quotationUpdate") {
        history.push(`/bid/change-user-quotation/${bidDetail.id}`);
        return;
      } else {
        history.push(`/bid/applyBid/${bidDetail.freightType}/${bidDetail.id}`);
      }
    };

    handleShipmentItemCargoUpdate(successCallback);
  };

  return (
    <>
      <Modal
        isOpened={true}
        handleClose={onItemUpdateModalClose}
        customModalBoxStyle={{
          minWidth: "1400px",
        }}
        modalBody={
          <FormProvider {...methods}>
            {bidDetail.freightType === "FCL" ? (
              <FCLItemForm
                onShipmentItemCargoUpdateSubmit={
                  checkNeedToChangeQuotationAndUpdateCargo
                }
                isImport={bidDetail.isImport}
              />
            ) : (
              <LCLAndAIRItemForm
                cargoInputMode={cargoInputMode}
                setCargoInputMode={setCargoInputMode}
                initialItemGroupList={initialItemGroupList}
                onShipmentItemCargoUpdateSubmit={
                  checkNeedToChangeQuotationAndUpdateCargo
                }
              />
            )}

            {showsQuotationResetModal && (
              <QuotationResetModal
                showsQuotationResetModal={showsQuotationResetModal}
                onQuotationResetModalClose={() =>
                  setShowsQuotationResetModal(false)
                }
                onShipmentItemCargoUpdate={handleShipmentItemCargoUpdateOnReset}
              />
            )}
          </FormProvider>
        }
      />

      {ResponseHandlerOfItemUpdateRequest}
    </>
  );
}

export default ItemUpdateModal;
