import { useSetAtom } from "jotai";

import { FORWARDING_ADMIN_AUTH_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/auth";

import { useAppMutation } from "../../../services/query";

import {
  ADMIN_LOGIN_REQ,
  ADMIN_LOGIN_RES,
  PARTNER_ADMIN_LOGIN_REQ,
  PARTNER_ADMIN_LOGIN_RES,
} from "../../../api-interfaces/shipda-api/admin/adminAuth";

function useAdminLogin() {
  const setLoggedIn = useSetAtom(FORWARDING_ADMIN_AUTH_ATOMS.ADMIN_LOGGED_IN);

  const mutation = useAppMutation<ADMIN_LOGIN_REQ, ADMIN_LOGIN_RES>({
    requestOptions: {
      method: "post",
      path: "/auth/login",
      apiType: "SHIPDA_ADMIN_API",
    },

    onSuccess: (data) => {
      const { accessToken, refreshToken, authority, user } = data;

      const { id, accountId } = user;

      // 보안을 고려하여 선택적으로 저장
      const currentUser = {
        id,
        authority,
        accountId,
      };

      window.localStorage.setItem("currentUser", JSON.stringify(currentUser));
      window.localStorage.setItem("accessToken", accessToken);
      window.localStorage.setItem("refreshToken", refreshToken);

      setLoggedIn(true);
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        let title = "";

        if (failureInfo) {
          const { code, error } = failureInfo;

          switch (code) {
            case 400:
            case 404: {
              title = "ID 혹은 비밀번호가 틀렸습니다.";
              break;
            }

            default: {
              title = "로그인 중 오류가 발생했습니다";
              break;
            }
          }
        }

        return {
          title,
          messageType: "titleOnly",
        };
      },
    },
  });

  return { ...mutation };
}

function usePartnerAdminLogin() {
  const setLoggedIn = useSetAtom(
    FORWARDING_ADMIN_AUTH_ATOMS.PARTNER_ADMIN_LOGGED_IN
  );

  const mutation = useAppMutation<
    PARTNER_ADMIN_LOGIN_REQ,
    PARTNER_ADMIN_LOGIN_RES
  >({
    requestOptions: {
      method: "post",
      path: "/auth/customs/login", // 아직은 customs로 관세사 로그인 전용으로 처리 (추후 다른 type의 파트너가 추가되는 경우 /auth/:partnerType/login 로그인을 지원할 예정)
      apiType: "SHIPDA_ADMIN_API",
    },

    onSuccess: async (data) => {
      const { accessToken, refreshToken, user } = data;

      // 보안을 고려하여 선택적으로 저장
      const currentUser = {
        id: user.id,
        name: user.name,
        partnerCompanyId: user.partnerCompanyId,
        company: user.company,
      };

      window.localStorage.setItem("currentUser", JSON.stringify(currentUser));
      window.localStorage.setItem("accessToken", accessToken);
      window.localStorage.setItem("refreshToken", refreshToken);

      setLoggedIn(true);
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        let title = "";

        if (failureInfo) {
          const { code, error } = failureInfo;

          switch (code) {
            case 400:
            case 404: {
              title = "ID 혹은 비밀번호가 틀렸습니다.";
              break;
            }

            default: {
              title = "로그인 중 오류가 발생했습니다";
              break;
            }
          }
        }

        return {
          title,
          messageType: "titleOnly",
        };
      },
    },
  });

  return { ...mutation };
}

const ADMIN_AUTH_QUERY = {
  useAdminLogin,
  usePartnerAdminLogin,
};

export default ADMIN_AUTH_QUERY;
