import { useAppMutation } from "../../../services/query";

import {
  CHANGE_CUSTOMS_ADMIN_USER_PASSWORD_REQ,
  CHANGE_CUSTOMS_ADMIN_USER_PASSWORD_RES,
} from "../../../api-interfaces/shipda-api/admin/customsAdminBidUser";

function useChangeCustomsAdminUserPassword() {
  const mutation = useAppMutation<
    CHANGE_CUSTOMS_ADMIN_USER_PASSWORD_REQ,
    CHANGE_CUSTOMS_ADMIN_USER_PASSWORD_RES
  >({
    requestOptions: {
      method: "patch",
      path: `/auth/customs`,
      apiType: "SHIPDA_ADMIN_API",
    },
  });

  return { ...mutation };
}

const CUSTOMS_ADMIN_USER_QUERY = {
  useChangeCustomsAdminUserPassword,
};

export default CUSTOMS_ADMIN_USER_QUERY;
