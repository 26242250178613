import { FormProvider, useForm, useWatch } from "react-hook-form";
import { Box, Button } from "@mui/material";

import { Port } from "@sellernote/_shared/src/types/common/common";
import {
  AdminBidDetail,
  AdminShipmentInfoForm,
} from "@sellernote/_shared/src/types/forwarding/adminBid";

import useShipmentInfoUpdateRequest from "./hooks/useShipmentInfoUpdateRequest";
import {
  getBooleanStringV2,
  getExportShipmentUpdatePayload,
  getFormWithDirty,
  getImportShipmentUpdatePayload,
  getIncotermsDefaultValueAndOptionList,
} from "./utils";

import Modal from "../../../../../components/Modal";
import QuotationResetModal from "../../components/QuotationResetModal";

import BusinessAgency from "./BusinessAgency";
import Checkpoint from "./Checkpoint";
import IncotermsForm from "./IncotermsForm";
import TransportSection from "./TransportInfo";

function UpdateShipmentInfoModal({
  showsUpdateShipmentInfoModal,
  setShowsShipmentInfoUpdateModalClose,
  portList,
  shipmentDetail,
}: {
  showsUpdateShipmentInfoModal: boolean;
  setShowsShipmentInfoUpdateModalClose: (value: boolean) => void;
  portList: Port[];
  shipmentDetail: AdminBidDetail;
}) {
  const defaultValue: AdminShipmentInfoForm = {
    incoterms: shipmentDetail.incoterms,
    startPortId: shipmentDetail.startPortId,
    startViaPortId: shipmentDetail.startViaPortId,
    startType: shipmentDetail.startType,
    startAddress: shipmentDetail.startAddress,
    endPortId: shipmentDetail.endPortId,
    endViaPortId: shipmentDetail.endViaPortId,
    endType: shipmentDetail.endType,
    endAddress: shipmentDetail.endAddress,
    endAddressDetail: shipmentDetail.endAddressDetail,
    containLss: getBooleanStringV2(shipmentDetail.containLss),

    containOceanSurcharge: getBooleanStringV2(
      shipmentDetail.containOceanSurcharge
    ),
    containDomesticFee: getBooleanStringV2(shipmentDetail.containDomesticFee),
    containerAccessable: getBooleanStringV2(shipmentDetail.containerAccessable),
    hopeCargoInsurance: shipmentDetail.hopeCargoInsurance,

    importCustoms: shipmentDetail.containCustoms,
    containerStuffing: getBooleanStringV2(shipmentDetail.containerStuffing),
    inlandTransportType: shipmentDetail.originInland,
    needFTACertificateAgency: shipmentDetail.needFTACertificateAgency,
    useVGM: shipmentDetail.useVGM,
    exportCustoms: shipmentDetail.containExportCustoms,
    zoneId: shipmentDetail.zoneId,
    inlandType: shipmentDetail.inlandType,
  };

  const methods = useForm<AdminShipmentInfoForm>({
    defaultValues: defaultValue,
  });

  const {
    control,
    formState: { dirtyFields },
    watch,
    reset,
    handleSubmit,
  } = methods;

  const startType = useWatch({
    control,
    name: "startType",
  });

  const incoterms = useWatch({
    control,
    name: "incoterms",
  });

  const endType = useWatch({
    control,
    name: "endType",
  });

  const hopeCargoInsurance = useWatch({
    control,
    name: "hopeCargoInsurance",
  });

  const importCustoms = useWatch({
    control,
    name: "importCustoms",
  });

  const isCTypeIncoterms =
    incoterms === "CFR" ||
    incoterms === "CIF" ||
    incoterms === "CPT" ||
    incoterms === "CIP";

  const incotermsDefaultValueAndOptions = getIncotermsDefaultValueAndOptionList(
    {
      freightType: shipmentDetail.freightType,

      isExport: !shipmentDetail.isImport,
      endType: endType,
      hopeCargoInsurance: hopeCargoInsurance,
      importCustoms: importCustoms,
    }
  );

  const {
    handleRecalculationCheckAndUpdate,
    handleShipmentInfoWithoutRecalculationUpdate,
    showsQuotationResetModal,
    setShowsQuotationResetModal,
    ResponseHandler: ResponseHandlerOfShipmentInfoUpdateRequest,
  } = useShipmentInfoUpdateRequest({
    shipmentId: shipmentDetail.id,
    requestPayload: shipmentDetail.isImport
      ? getImportShipmentUpdatePayload(
          getFormWithDirty({ dirtyFields, formData: watch() })
        )
      : getExportShipmentUpdatePayload(
          getFormWithDirty({ dirtyFields, formData: watch() })
        ),
    shipmentDetail: shipmentDetail,
  });

  const handleShipmentInfoUpdateModalClose = () => {
    // 모달 종료 시 변경된 값이 있다면 초기화
    reset(defaultValue);
    setShowsShipmentInfoUpdateModalClose(false);
  };

  return (
    <>
      <Modal
        isOpened={showsUpdateShipmentInfoModal}
        handleClose={handleShipmentInfoUpdateModalClose}
        modalBody={
          <FormProvider {...methods}>
            <Box
              display="flex"
              flexDirection="column"
              gap={1}
              width={500}
              component={"form"}
              onSubmit={handleSubmit(handleRecalculationCheckAndUpdate)}
            >
              <TransportSection
                portList={portList}
                isCTypeIncoterms={isCTypeIncoterms}
                shipmentDetail={shipmentDetail}
              />

              <Checkpoint
                freightType={shipmentDetail.freightType}
                isImport={shipmentDetail.isImport}
              />

              <IncotermsForm
                incotermsDefaultValueAndOptions={
                  incotermsDefaultValueAndOptions
                }
              />

              <BusinessAgency
                freightType={shipmentDetail.freightType}
                isImport={shipmentDetail.isImport}
              />

              <Button type="submit" variant="contained">
                수정
              </Button>
            </Box>

            {showsQuotationResetModal && (
              <QuotationResetModal
                showsQuotationResetModal={showsQuotationResetModal}
                onQuotationResetModalClose={() =>
                  setShowsQuotationResetModal(false)
                }
                onShipmentItemCargoUpdate={
                  handleShipmentInfoWithoutRecalculationUpdate
                }
              />
            )}
          </FormProvider>
        }
      />

      {ResponseHandlerOfShipmentInfoUpdateRequest}
    </>
  );
}

export default UpdateShipmentInfoModal;
