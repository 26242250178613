import React, { useCallback, useMemo, useState } from "react";
import { Button, Checkbox, Input, Modal, Table } from "antd";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import CUSTOMS_ADMIN_TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/CUSTOMS_ADMIN_TRELLO_BID_QUERY";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import { TrelloBidDetail } from "@sellernote/_shared/src/types/forwarding/trello";

import useSnackbar from "../../../../../../hooks/useSnackbar";

import Styled from "./index.styles";

interface RequiredDocumentDataSource {
  key: string;
  type: string;
}

const requiredDocumentDataSource = [
  {
    key: "C/I&P/L",
    type: "C/I&P/L",
  },
  {
    key: "C/I&P/L comment",
    type: "코멘트",
  },
  {
    key: "C/I&P/L guide",
    type: "C/I&P/L 가이드",
  },
  {
    key: "C/I&P/L guide comment",
    type: "코멘트",
  },
  {
    key: "C/O",
    type: "C/O",
  },
  {
    key: "C/O comment",
    type: "코멘트",
  },
  {
    key: "brn",
    type: "사업자등록증",
  },
  {
    key: "brn comment",
    type: "코멘트",
  },
  {
    key: "certificate",
    type: "인증서류",
  },
  {
    key: "certificate comment",
    type: "코멘트",
  },
  {
    key: "etc",
    type: "기타서류",
  },
  {
    key: "etc comment",
    type: "코멘트",
  },
];

const RequiredDocumentModal = ({
  showRequiredDocumentModal,
  setShowRequiredDocumentModal,
  trelloDetail,
  hasCIPLAttachment,
  hasCOAttachment,
  hasBrnAttachment,
  hasCertificateAttachment,
  hasEtcAttachment,
}: {
  showRequiredDocumentModal: boolean;
  setShowRequiredDocumentModal: React.Dispatch<React.SetStateAction<boolean>>;
  trelloDetail: TrelloBidDetail;
  hasCIPLAttachment: boolean;
  hasCOAttachment: boolean;
  hasBrnAttachment: boolean;
  hasCertificateAttachment: boolean;
  hasEtcAttachment: boolean;
}) => {
  const { handleSnackbarOpen } = useSnackbar();

  const [ciCheckValue, setCiCheckValue] = useState(!hasCIPLAttachment);
  const [coCheckValue, setCoCheckValue] = useState(
    trelloDetail.needFTACertificateAgency ? false : !hasCOAttachment
  );
  const [brnCheckValue, setBrnCheckValue] = useState(!hasBrnAttachment);
  const [ciGuideCheckValue, setCiGuideCheckValue] = useState(false);
  const [certificateCheckValue, setCertificateCheckValue] = useState(false);
  const [etcCheckValue, setEtcCheckValue] = useState(false);
  const [ciInputValue, setCiInputValue] = useState(
    "C/I(Commercial Invoice, 상업송장) 및 P/L(Packing List, 포장명세서)는 수출자(판매자)가 수입자(구매자)에게 제공하는 서류로 통관을 위해 쉽다에 전달해야 하는 자료입니다.​"
  );
  const [coInputValue, setCoInputValue] = useState(
    "C/O(Certificate of Origin, 원산지증명서): 상품의 원산지를 나타내는 문서로 해당 서류를 국내 세관에 제출 시, 관세율을 절감할 수 있습니다. 수출자(판매자)로부터 수령하여 업로드 요청 드립니다."
  );
  const [brnInputValue, setBrnInputValue] = useState(
    "통관 및 정산을 위해 귀사의 사업자등록증을 업로드 요청 드립니다."
  );
  const [certificateInputValue, setCertificateInputValue] = useState(
    "수입요건이 존재하는 상품의 경우, 수입통관 시 인증서류가 필요합니다. 해당 서류를 구비하여 업로드 요청드립니다. "
  );
  const [etcInputValue, setEtcInputValue] = useState(
    "수입통관 시 참조해야 하는 기타서류가 있다면 업로드 요청 드립니다. "
  );
  const [ciGuideInputValue, setCiGuideInputValue] = useState(
    "수출자(판매자)가 C/I&P/L를 제공하기 어려운 경우 첨부문서를 참고해서 직접 작성 및 업로드 요청 드립니다."
  );

  const {
    mutate: sendRequiredDocumentEmail,
    ResponseHandler: ResponseHandlerOfUseSendRequiredDocumentEmail,
  } = TRELLO_BID_QUERY.useSendRequiredDocumentEmail({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
      },
      customizeMessage: () => ({
        title: "필요서류 요청 이메일을 보냈습니다.",
      }),
    },
    bidId: trelloDetail.id,
  });

  const {
    mutate: sendCustomsRequiredDocumentEmail,
    ResponseHandler: ResponseHandlerOfSendCustomsRequiredDocumentEmail,
  } = CUSTOMS_ADMIN_TRELLO_BID_QUERY.useSendCustomsRequiredDocumentEmail(
    trelloDetail.id
  );

  const requestSendRequiredDocumentEmail = useCallback(() => {
    let documentDetail = [];
    if (ciGuideCheckValue) {
      documentDetail = [
        {
          documentName: "C/I & P/L",
          uploadFlag: !ciCheckValue,
          comment: ciCheckValue ? ciInputValue : undefined,
        },
        {
          documentName: "sampleGuide",
          uploadFlag: ciGuideCheckValue,
          comment: ciGuideCheckValue ? ciGuideInputValue : undefined,
        },
        {
          documentName: "C/O",
          uploadFlag: !coCheckValue,
          comment: coCheckValue ? coInputValue : undefined,
        },
        {
          documentName: "사업자등록증",
          uploadFlag: !brnCheckValue,
          comment: brnCheckValue ? brnInputValue : undefined,
        },
        {
          documentName: "인증서류",
          uploadFlag: !certificateCheckValue,
          comment: certificateCheckValue ? certificateInputValue : undefined,
        },
        {
          documentName: "기타서류",
          uploadFlag: !etcCheckValue,
          comment: etcCheckValue ? etcInputValue : undefined,
        },
      ];
    } else {
      documentDetail = [
        {
          documentName: "C/I & P/L",
          uploadFlag: !ciCheckValue,
          comment: ciCheckValue ? ciInputValue : undefined,
        },
        {
          documentName: "C/O",
          uploadFlag: !coCheckValue,
          comment: coCheckValue ? coInputValue : undefined,
        },
        {
          documentName: "사업자등록증",
          uploadFlag: !brnCheckValue,
          comment: brnCheckValue ? brnInputValue : undefined,
        },
        {
          documentName: "인증서류",
          uploadFlag: !certificateCheckValue,
          comment: certificateCheckValue ? certificateInputValue : undefined,
        },
        {
          documentName: "기타서류",
          uploadFlag: !etcCheckValue,
          comment: etcCheckValue ? etcInputValue : undefined,
        },
      ];
    }
    if (APP_NAME === "shipda-admin") {
      return sendRequiredDocumentEmail({ documentDetail });
    }
    return sendCustomsRequiredDocumentEmail(
      { documentDetail },
      {
        onSuccess: () => {
          handleSnackbarOpen("필요서류 요청 이메일을 보냈습니다.");
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  }, [
    brnCheckValue,
    brnInputValue,
    certificateCheckValue,
    certificateInputValue,
    ciCheckValue,
    ciGuideCheckValue,
    ciGuideInputValue,
    ciInputValue,
    coCheckValue,
    coInputValue,
    etcCheckValue,
    etcInputValue,
    handleSnackbarOpen,
    sendCustomsRequiredDocumentEmail,
    sendRequiredDocumentEmail,
  ]);

  const columns = useMemo(() => {
    return [
      {
        title: "구분",
        render: (record: RequiredDocumentDataSource) => {
          if (record.key === "C/I&P/L comment") {
            return {
              children: (
                <div className="comment-container">
                  <div className="label">코멘트:</div>
                  {ciCheckValue ? (
                    <Input.TextArea
                      style={{ marginTop: -4 }}
                      bordered={false}
                      autoSize={true}
                      defaultValue={ciInputValue}
                      onChange={(e) => setCiInputValue(e.target.value)}
                    ></Input.TextArea>
                  ) : undefined}
                </div>
              ),
              props: {
                colSpan: 3,
              },
            };
          }
          if (record.key === "C/I&P/L guide comment") {
            return {
              children: (
                <div className="comment-container">
                  <div className="label">코멘트:</div>
                  {ciGuideCheckValue ? (
                    <Input.TextArea
                      style={{ marginTop: -4 }}
                      bordered={false}
                      autoSize={true}
                      defaultValue={ciGuideInputValue}
                      onChange={(e) => setCiGuideInputValue(e.target.value)}
                    ></Input.TextArea>
                  ) : undefined}
                </div>
              ),
              props: {
                colSpan: 3,
              },
            };
          }
          if (record.key === "C/O comment") {
            return {
              children: (
                <div className="comment-container">
                  <div className="label">코멘트:</div>
                  {coCheckValue ? (
                    <Input.TextArea
                      style={{ marginTop: -4 }}
                      bordered={false}
                      autoSize={true}
                      defaultValue={coInputValue}
                      onChange={(e) => setCoInputValue(e.target.value)}
                    ></Input.TextArea>
                  ) : undefined}
                </div>
              ),
              props: {
                colSpan: 3,
              },
            };
          }
          if (record.key === "brn comment") {
            return {
              children: (
                <div className="comment-container">
                  <div className="label">코멘트:</div>
                  {brnCheckValue ? (
                    <Input.TextArea
                      style={{ marginTop: -4 }}
                      bordered={false}
                      autoSize={true}
                      defaultValue={brnInputValue}
                      onChange={(e) => setBrnInputValue(e.target.value)}
                    ></Input.TextArea>
                  ) : undefined}
                </div>
              ),
              props: {
                colSpan: 3,
              },
            };
          }
          if (record.key === "certificate comment") {
            return {
              children: (
                <div className="comment-container">
                  <div className="label">코멘트:</div>

                  {certificateCheckValue ? (
                    <Input.TextArea
                      style={{ marginTop: -4 }}
                      bordered={false}
                      autoSize={true}
                      defaultValue={certificateInputValue}
                      onChange={(e) => setCertificateInputValue(e.target.value)}
                    ></Input.TextArea>
                  ) : undefined}
                </div>
              ),
              props: {
                colSpan: 3,
              },
            };
          }
          if (record.key === "etc comment") {
            return {
              children: (
                <div className="comment-container">
                  <div className="label">코멘트:</div>

                  {etcCheckValue ? (
                    <Input.TextArea
                      style={{ marginTop: -4 }}
                      bordered={false}
                      autoSize={true}
                      defaultValue={etcInputValue}
                      onChange={(e) => setEtcInputValue(e.target.value)}
                    ></Input.TextArea>
                  ) : undefined}
                </div>
              ),
              props: {
                colSpan: 3,
              },
            };
          }

          return <div style={{ fontWeight: "bold" }}>{record.type}</div>;
        },
      },
      {
        title: "파일 업로드 여부",
        render: (record: RequiredDocumentDataSource) => {
          if (record.type === "C/I&P/L") {
            return <div>{hasCIPLAttachment ? "Y" : "N"}</div>;
          }
          if (record.type === "C/O") {
            return <div>{hasCOAttachment ? "Y" : "N"}</div>;
          }
          if (record.type === "사업자등록증") {
            return <div>{hasBrnAttachment ? "Y" : "N"}</div>;
          }
          if (record.type === "인증서류") {
            return <div>{hasCertificateAttachment ? "Y" : "N"}</div>;
          }
          if (record.type === "기타서류") {
            return <div>{hasEtcAttachment ? "Y" : "N"}</div>;
          }
          return {
            children: undefined,
            props: {
              colSpan: 1,
            },
          };
        },
      },
      {
        title: "서류요청",
        render: (record: RequiredDocumentDataSource) => {
          if (record.type === "C/I&P/L") {
            return (
              <Checkbox
                className="checkbox"
                defaultChecked={ciCheckValue}
                onChange={(e) => setCiCheckValue(e.target.checked)}
              ></Checkbox>
            );
          }
          if (record.type === "C/I&P/L 가이드") {
            return (
              <Checkbox
                className="checkbox"
                defaultChecked={ciGuideCheckValue}
                onChange={(e) => setCiGuideCheckValue(e.target.checked)}
              ></Checkbox>
            );
          }
          if (record.type === "C/O") {
            return (
              <Checkbox
                className="checkbox"
                defaultChecked={coCheckValue}
                onChange={(e) => setCoCheckValue(e.target.checked)}
              ></Checkbox>
            );
          }
          if (record.type === "사업자등록증") {
            return (
              <Checkbox
                className="checkbox"
                defaultChecked={brnCheckValue}
                onChange={(e) => setBrnCheckValue(e.target.checked)}
              ></Checkbox>
            );
          }
          if (record.type === "인증서류") {
            return (
              <Checkbox
                className="checkbox"
                defaultChecked={certificateCheckValue}
                onChange={(e) => setCertificateCheckValue(e.target.checked)}
              ></Checkbox>
            );
          }
          if (record.type === "기타서류") {
            return (
              <Checkbox
                className="checkbox"
                defaultChecked={etcCheckValue}
                onChange={(e) => setEtcCheckValue(e.target.checked)}
              ></Checkbox>
            );
          }
          return {
            children: undefined,
            props: {
              colSpan: 0,
            },
          };
        },
      },
    ];
  }, [
    brnCheckValue,
    brnInputValue,
    certificateCheckValue,
    certificateInputValue,
    ciCheckValue,
    ciGuideCheckValue,
    ciGuideInputValue,
    ciInputValue,
    coCheckValue,
    coInputValue,
    etcCheckValue,
    etcInputValue,
    hasBrnAttachment,
    hasCIPLAttachment,
    hasCOAttachment,
    hasCertificateAttachment,
    hasEtcAttachment,
  ]);

  return (
    <Modal
      width={766}
      bodyStyle={{ padding: "24px 16px 40px 16px" }}
      title=""
      visible={showRequiredDocumentModal}
      onCancel={() => setShowRequiredDocumentModal(false)}
      footer={null}
    >
      <Styled.container>
        <div className="title">필요서류 요청하기</div>

        <Table
          className="table"
          dataSource={requiredDocumentDataSource}
          columns={columns}
          rowKey={(record: RequiredDocumentDataSource) => record.key}
          pagination={false}
        />

        <div className="button-wrapper">
          <Button
            disabled={
              !ciCheckValue &&
              !coCheckValue &&
              !brnCheckValue &&
              !certificateCheckValue &&
              !etcCheckValue
            }
            type="primary"
            style={{ width: 154, borderRadius: 2 }}
            onClick={requestSendRequiredDocumentEmail}
          >
            요청하기
          </Button>
        </div>
      </Styled.container>

      {ResponseHandlerOfUseSendRequiredDocumentEmail}
      {ResponseHandlerOfSendCustomsRequiredDocumentEmail}
    </Modal>
  );
};

export default RequiredDocumentModal;
