import React, { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import axios from "axios";
import { useAtomValue } from "jotai";

import { GET_PRESIGNED_URL_RES_DATA } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminFile";
import { APP_NAME } from "@sellernote/_shared/src/constants";
import { ADMIN_BID_USER_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_BID_USER_QUERY";
import ADMIN_FILE_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_FILE_QUERY";
import { ADMIN_ORDER_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_ORDER_QUERY";
import { ADMIN_PROMOTION_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_PROMOTION_QUERY";
import { ADMIN_TEAM_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_TEAM_QUERY";
import { CUSTOMS_ADMIN_TRELLO_BID_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/admin/CUSTOMS_ADMIN_TRELLO_BID_QUERY";
import { TRELLO_BID_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import {
  BidUploadDataType,
  FileExtension,
  FileTypes,
} from "@sellernote/_shared/src/types/common/upload";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";
import FileUpload from "@sellernote/_shared-for-forwarding-admin/src/components/FileUpload";

import useSnackbar from "../../hooks/useSnackbar";

import { FORWARDING_ADMIN_AUTH_SELECTORS } from "../../jotaiStates/auth";

const UploadModal = ({
  showsUploadModal,
  setShowsUploadModal,
  type,
  id,
  dataType,
  isPermission,
  bidAccountPayableId,
  teamId,
  trelloId,
  acceptedFileTypes = [
    "doc",
    "docx",
    "txt",
    "hwp",
    "hwpx",
    "rtf",
    "xls",
    "xlsx",
    "csv",
    "jpeg",
    "pdf",
    "jpg",
    "png",
    "tif",
    "gif",
    "svg",
    "ppt",
    "pptx",
  ],
  warningMessage = "*현재 hwpx, rtf, csv은 파일 미리보기를 지원하지 않습니다.",
  isMultiple = true,
  allowedFileSize,
}: {
  showsUploadModal: boolean;
  setShowsUploadModal: React.Dispatch<React.SetStateAction<boolean>>;
  type: string;
  id: number;
  dataType: BidUploadDataType;
  isPermission: boolean;
  bidAccountPayableId?: number;
  teamId?: number;
  trelloId?: number;
  acceptedFileTypes?: FileExtension[];
  warningMessage?: string;
  isMultiple?: boolean;
  allowedFileSize?: number;
}) => {
  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const [fileList, setFileList] = useState<FileTypes[]>([]);

  const {
    mutate: getPresignedURL,
    ResponseHandler: ResponseHandlerOfGetPresignedURL,
  } = ADMIN_FILE_QUERY.useGetPresignedURL({
    bidId: id,
    isPermission: APP_NAME === "shipda-admin" ? isPermission : false,
  });
  const {
    mutate: bindFileAfterUpload,
    ResponseHandler: ResponseHandlerOfBindFileAfterUpload,
  } = ADMIN_FILE_QUERY.useBindFileAfterUpload();

  const invalidateQueriesByDataType = useCallback(() => {
    if (dataType === "trello") {
      queryClient.invalidateQueries(
        TRELLO_BID_QUERY_KEY_GEN.getTrelloAttachments({
          // brn일때는 id가 userId인 상태라 갱신이 안됨 따로 trelloId를 넘겨받아서 갱신한다.
          bidId: type === "brn" ? (trelloId as number) : id,
        })
      );

      // 공문(shipLetter) 업로드 시에는 attachments 업데이트만 해주면 되기 때문에 조건 추가
      if (APP_NAME === "shipda-admin" && type !== "shipLetter") {
        queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.trelloDetail());
      } else {
        queryClient.invalidateQueries(
          CUSTOMS_ADMIN_TRELLO_BID_QUERY_KEY_GEN.getCustomsTrelloBidDetail({
            bidId: id,
          })
        );
      }
    }

    if (dataType === "partner") {
      queryClient.invalidateQueries(["forwarding", "partner", "list"]);
    }

    if (dataType === "promotion") {
      queryClient.invalidateQueries(ADMIN_PROMOTION_QUERY_KEY_GEN.all());
    }

    if (dataType === "user") {
      return queryClient.invalidateQueries(
        ADMIN_BID_USER_QUERY_KEY_GEN.getAdminNewBidUserDetail(id)
      );
    }

    if (dataType === "order") {
      queryClient.invalidateQueries(
        ADMIN_ORDER_QUERY_KEY_GEN.getAdminOrderDetail({ id })
      );
    }

    if (dataType === "team") {
      queryClient.invalidateQueries(
        ADMIN_TEAM_QUERY_KEY_GEN.getAdminTeamDetail(id)
      );
    }
  }, [dataType, id, queryClient, trelloId, type]);

  const uploadFile = useCallback(() => {
    // isPermission 업로드일 때 정산 관리자는 업로드 할 권한이 없음(401 에러 리턴)
    if (currentAdminAuthInfo?.authority === "finance" && isPermission) {
      handleSnackbarOpen("정산 관리자는 파일 업로드 권한이 없습니다.", "error");
      return;
    }
    const requestFileData = fileList.map((v: FileTypes) => {
      return {
        mimeType: v.fileInfo.type,
        name: v.fileInfo.name,
        type,
      };
    });

    const flatS3Data = fileList.flat();

    getPresignedURL(
      {
        presignedUrlOptions: requestFileData,
        isPublic: false,
        bidAccountPayableId,
        targetId:
          dataType === "order" || type === "shipLetter" ? id : undefined,
      },
      {
        onSuccess: async ({ data: successData }) => {
          let index = 0;
          for await (const param of successData) {
            await axios.put(param.url, flatS3Data[index].fileInfo, {
              headers: { "Content-Type": flatS3Data[index].fileInfo.type },
            });
            index += 1;
          }

          const bindingData = successData.map(
            (v: GET_PRESIGNED_URL_RES_DATA) => {
              return {
                key: v.key,
                domain: type,
                targetId: id,
                teamId: teamId,
              };
            }
          );

          bindFileAfterUpload(
            { bindList: bindingData },
            {
              onSuccess: () => {
                handleSnackbarOpen("요청에 성공했습니다.");
                setFileList([]);
                invalidateQueriesByDataType();
              },
              onError: () => {
                handleSnackbarOpen("파일 바인딩에 실패했습니다.", "error");
              },
            }
          );
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  }, [
    currentAdminAuthInfo?.authority,
    isPermission,
    fileList,
    getPresignedURL,
    bidAccountPayableId,
    dataType,
    type,
    id,
    handleSnackbarOpen,
    bindFileAfterUpload,
    teamId,
    invalidateQueriesByDataType,
  ]);

  const handleModalClose = useCallback(() => {
    setShowsUploadModal(false);
  }, [setShowsUploadModal]);

  return (
    <>
      <Modal
        handleClose={handleModalClose}
        isOpened={showsUploadModal}
        showsCloseIcon={true}
        modalBody={
          <FileUpload
            showsFileExtensionMessage={true}
            isMultiple={isMultiple}
            files={fileList}
            setFiles={setFileList}
            handleUpload={uploadFile}
            warningMessage={warningMessage}
            acceptedFileTypes={acceptedFileTypes}
            allowedFileSize={allowedFileSize}
          />
        }
      />

      {ResponseHandlerOfBindFileAfterUpload}
      {ResponseHandlerOfGetPresignedURL}
    </>
  );
};

export default UploadModal;
