import { useCallback, useMemo, useState } from "react";
import { Typography } from "@mui/material";

import ADMIN_FILE_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_FILE_QUERY";
import {
  FileCategoryTranslatedToKr,
  ForwardingAdminAttachment,
} from "@sellernote/_shared/src/types/common/file";
import { BidUploadDataType } from "@sellernote/_shared/src/types/common/upload";
import { BidProjectStatus } from "@sellernote/_shared/src/types/forwarding/bid";
import { omitWithEllipsis } from "@sellernote/_shared/src/utils/common/string";

import useSnackbar from "../../hooks/useSnackbar";

import FilePreview from "./FilePreview";

function FileName({
  attachment,
  projectStatus,
  isWithdrawn,
  dataType,
  fileCategory,
  id,
  isPurchaseRequest,
}: {
  attachment: ForwardingAdminAttachment;
  projectStatus?: BidProjectStatus;
  isWithdrawn?: boolean;
  dataType: BidUploadDataType;
  fileCategory?: FileCategoryTranslatedToKr;
  id?: number;
  isPurchaseRequest?: boolean;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const [showsFilePreview, setShowsFilePreview] = useState(false);
  const [s3Url, setS3Url] = useState("");
  const [fileName, setFileName] = useState("");

  const {
    mutate: getFileDownloadUrl,
    ResponseHandler: ResponseHandlerOfGetFileDownloadUrl,
  } = ADMIN_FILE_QUERY.useGetFileDownloadUrl();

  const fileExtension = useMemo(() => {
    const fileExtensionIndex = attachment.name.lastIndexOf(".");
    return attachment.name.substring(fileExtensionIndex + 1);
  }, [attachment.name]);

  const handleFilePreviewClose = useCallback(() => {
    setS3Url("");
    setShowsFilePreview(false);
    setFileName("");
  }, []);

  const handleFilePreviewOpen = useCallback(
    (downloadKey: string, fileName: string) => {
      return () => {
        if (!downloadKey) {
          return;
        }

        getFileDownloadUrl(
          {
            pathParams: {
              key: downloadKey,
            },
          },
          {
            onSuccess: ({ data }) => {
              setS3Url(data.url);
              setShowsFilePreview(true);
              setFileName(fileName);
            },

            onError: () => {
              handleSnackbarOpen("요청에 실패했습니다.", "error");
            },
          }
        );
      };
    },
    [getFileDownloadUrl, handleSnackbarOpen]
  );

  return (
    <>
      <Typography
        sx={{ cursor: "pointer" }}
        onClick={handleFilePreviewOpen(attachment.key, attachment.name)}
        variant="body1"
        component="div"
      >
        {omitWithEllipsis({
          src: attachment.name,
          maxLength: 15,
          ellipsis: `...${fileExtension}`,
        })}
      </Typography>

      {showsFilePreview && (
        <FilePreview
          isPurchaseRequest={isPurchaseRequest}
          fileName={fileName}
          s3Url={s3Url}
          fileExtension={fileExtension}
          handleFilePreviewClose={handleFilePreviewClose}
          visible={showsFilePreview}
          fileCategory={fileCategory}
          id={id}
          projectStatus={projectStatus}
          isWithdrawn={isWithdrawn}
          dataType={dataType}
          attachment={attachment}
        />
      )}

      {ResponseHandlerOfGetFileDownloadUrl}
    </>
  );
}

export default FileName;
