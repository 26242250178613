import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { Box, Button, Typography } from "@mui/material";

import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";

import useSnackbar from "../../../../../../hooks/useSnackbar";

function DeleteBidAccountPayableModal({
  opensDeleteBidAccountPayableModal,
  setOpensDeleteBidAccountPayableModal,
  bidAccountPayableId,
  processSuccessResponse,
}: {
  opensDeleteBidAccountPayableModal: boolean;
  setOpensDeleteBidAccountPayableModal: Dispatch<SetStateAction<boolean>>;
  bidAccountPayableId: number;
  processSuccessResponse: (message: string) => void;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const {
    mutate: deleteAccountPayable,
    ResponseHandler: ResponseHandlerOfDeleteAccountPayable,
  } = TRELLO_BID_QUERY.useDeleteAccountPayable();

  const handleModalClose = useCallback(() => {
    setOpensDeleteBidAccountPayableModal(false);
  }, [setOpensDeleteBidAccountPayableModal]);

  const handleBidAccountPayableModalDelete = useCallback(() => {
    return () => {
      deleteAccountPayable(
        {
          pathParams: { bidAccountPayableId },
        },
        {
          onSuccess: () => {
            processSuccessResponse("삭제했습니다.");
            handleModalClose();
          },

          onError: () => {
            handleSnackbarOpen("요청에 실패했습니다.", "error");
          },
        }
      );
    };
  }, [
    deleteAccountPayable,
    bidAccountPayableId,
    processSuccessResponse,
    handleModalClose,
    handleSnackbarOpen,
  ]);

  const ModalBody = useMemo(() => {
    return (
      <>
        <Typography variant="h6" component="div">
          삭제하시겠습니까?
        </Typography>

        <Box display="flex" justifyContent="center" gap={1} mt={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleBidAccountPayableModalDelete()}
          >
            예
          </Button>

          <Button
            variant="contained"
            color="success"
            onClick={handleModalClose}
          >
            아니오
          </Button>
        </Box>
      </>
    );
  }, [handleBidAccountPayableModalDelete, handleModalClose]);

  return (
    <>
      <Modal
        isOpened={opensDeleteBidAccountPayableModal}
        handleClose={handleModalClose}
        modalBody={ModalBody}
      />

      {ResponseHandlerOfDeleteAccountPayable}
    </>
  );
}

export default DeleteBidAccountPayableModal;
